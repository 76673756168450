// import { Link } from "react-router-dom";
import Logo from "/magicreview.svg";
import { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { usePostLogin } from "../../query/useAuth";
import { toast } from "sonner";
import ButtonLoading from "../../components/common/ButtonLoading";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const { mutate, isPending } = usePostLogin();

  const handleLogin = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        email: email,
        password: password,
      },
      {
        onSuccess: (res) => {
          localStorage.setItem("token", res.token);
          toast.success("Logged in successfully");
          navigate("/");
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.data.detail) {
              toast.error(err.response?.data.detail);
            }
          } else {
            toast.error("Something went wrong!");
          }
        },
      }
    );
  };

  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center gap-y-5">
      <img src={Logo} alt="MagicReview Logo" />
      <h1 className="font-opensans font-bold text-2xl lg:text-[32px] lg:tracking-tighter">
        MagicReview Admin
      </h1>
      <p className="font-montserrat text-[15px] text-[#101828aa]">
        Login to Admin Panel
      </p>
      <form
        className="font-montserrat w-full lg:w-[430px] space-y-5 px-5 lg:px-0"
        onSubmit={handleLogin}
      >
        <div className="flex flex-col space-y-2">
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            id="email"
            placeholder="e.g. mail@domain.com"
            className="border text-sm rounded-[4px] px-5 py-3  focus:appearance-none focus:ring-0 focus:outline-none focus:border-primary"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-2 relative">
          <label htmlFor="email">Password</label>
          <input
            type={isShowPassword ? "text" : "password"}
            id="email"
            placeholder="************"
            className="border text-sm rounded-[4px] px-5 py-3
            focus:appearance-none focus:ring-0 focus:outline-none focus:border-primary placeholder:pt-7"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="absolute top-1/2 -translate-y-0.5 right-5 cursor-pointer">
            {isShowPassword ? (
              <EyeOff
                className="text-[#81818F] w-5 h-5"
                onClick={() => setIsShowPassword(false)}
                role="button"
              />
            ) : (
              <Eye
                className="text-[#81818F] w-5 h-5"
                onClick={() => setIsShowPassword(true)}
                role="button"
                data-testid="eye"
              />
            )}
          </div>
        </div>
        {/* <div className="flex justify-end font-semibold text-sm text-primary">
          <Link to={"/forgot-password"}>Forgotten password?</Link>
        </div> */}
        <button
          disabled={!email || !password || isPending}
          type="submit"
          className="w-full text-center text-white bg-primary border border-primary rounded-[7px] hover:text-primary hover:bg-white py-3 transition-all duration-200 cursor-pointer group"
        >
          {isPending ? <ButtonLoading /> : "Log In"}
        </button>
      </form>
    </div>
  );
};

export default Login;

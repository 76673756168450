import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteCouponApi,
  deleteDeletePlansApi,
  getCouponsApi,
  getPlansApi,
  postAddCouponApi,
  postAddPlansApi,
  putEditPlansApi,
} from "../api/subscription";
import {
  DeleteCouponProps,
  GetCouponProps,
  GetCouponReturn,
  GetPlansReturn,
  PostAddCouponProps,
  PostAddPlanProps,
} from "../types/types";

export const useGetCoupons = (params: GetCouponProps) => {
  return useQuery<{ total_results: number; results: Array<GetCouponReturn> }>({
    queryKey: ["getCoupons", params],
    queryFn: () => getCouponsApi(params),
  });
};

export const usePostAddCoupon = () => {
  return useMutation({
    mutationKey: ["postAddCoupon"],
    mutationFn: (data: PostAddCouponProps) => postAddCouponApi(data),
  });
};

export const useDeleteCoupon = () => {
  return useMutation({
    mutationKey: ["deleteCoupon"],
    mutationFn: (data: DeleteCouponProps) => deleteCouponApi(data),
  });
};

export const useGetPlans = () => {
  return useQuery<Array<GetPlansReturn>>({
    queryKey: ["getPlans"],
    queryFn: () => getPlansApi(),
  });
};

export const usePostAddPlans = () => {
  return useMutation({
    mutationKey: ["postAddPlans"],
    mutationFn: (data: PostAddPlanProps) => postAddPlansApi(data),
  });
};

export const usePutEditPlans = () => {
  return useMutation({
    mutationKey: ["putEditPlans"],
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: Partial<PostAddPlanProps>;
    }) => putEditPlansApi({ id, data }),
  });
};

export const useDeletePlans = () => {
  return useMutation({
    mutationKey: ["deletePlans"],
    mutationFn: ({ id, password }: { id: string; password: string }) =>
      deleteDeletePlansApi({ id: id, password: password }),
  });
};

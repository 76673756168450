import { useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import TimeSelectDropdown from "../common/TimeSelectDropdown";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomToolTip from "./CustomToolTip";

const Activities = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const isLargerScreen = windowSize.width >= 1920;
  const [focusBar, setFocusBar] = useState<number>(0);
  const [bargraphHeight, setBargraphHeight] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  let data;
  let period;
  let aiReviewPostedAggregation;

  if (period === "monthly") {
    // Change the structure for yearly period
    data = [
      {
        name: "Jan",
        review: aiReviewPostedAggregation?.jan,
      },
      {
        name: "Feb",
        review: aiReviewPostedAggregation?.feb,
      },
      {
        name: "Mar",
        review: aiReviewPostedAggregation?.mar,
      },
      {
        name: "Apr",
        review: aiReviewPostedAggregation?.apr,
      },
      {
        name: "May",
        review: aiReviewPostedAggregation?.may,
      },
      {
        name: "Jun",
        review: aiReviewPostedAggregation?.jun,
      },
      {
        name: "Jul",
        review: aiReviewPostedAggregation?.jul,
      },
      {
        name: "Aug",
        review: aiReviewPostedAggregation?.aug,
      },
      {
        name: "Sep",
        review: aiReviewPostedAggregation?.sep,
      },
      {
        name: "Oct",
        review: aiReviewPostedAggregation?.oct,
      },
      {
        name: "Nov",
        review: aiReviewPostedAggregation?.nov,
      },

      {
        name: "Dec",
        review: aiReviewPostedAggregation?.dec,
      },
      // Add other months as needed
    ];
  } else if (period === "yearly") {
    data = aiReviewPostedAggregation
      ? Object.keys(aiReviewPostedAggregation).map((year) => ({
          name: year,
          review: aiReviewPostedAggregation[year],
        }))
      : [];
  } else {
    // Default structure for other periods (e.g., weekly, monthly)
    data = [
      {
        name: "MON",
        review: aiReviewPostedAggregation?.mon,
      },
      {
        name: "TUE",
        review: aiReviewPostedAggregation?.tue,
      },
      {
        name: "WED",
        review: aiReviewPostedAggregation?.wed,
      },
      {
        name: "THU",
        review: aiReviewPostedAggregation?.thu,
      },
      {
        name: "FRI",
        review: aiReviewPostedAggregation?.fri,
      },
      {
        name: "SAT",
        review: aiReviewPostedAggregation?.sat,
      },
      {
        name: "SUN",
        review: aiReviewPostedAggregation?.sun,
      },
    ];
  }
  return (
    <div className="w-full xl:w-[57.5%] p-5 rounded-[12px] bg-white min-h-max">
      <div className="flex justify-between items-center">
        <p className=" text-xl font-medium">Activity</p>
        <div>
          <TimeSelectDropdown />
        </div>
      </div>
      <ResponsiveContainer
        height={isMobile ? 200 : isLargerScreen ? 300 : 240}
        className="w-full mt-5"
      >
        <AreaChart
          data-testid="activities-chart"
          className="w-full h-full text-sm"
          data={data}
          margin={{
            top: 5,
            bottom: 5,
          }}
          barSize={isLargerScreen ? 50 : isMobile ? 27 : 35}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{
              left: isLargerScreen ? 25 : 17,
              right: isLargerScreen ? 25 : 17,
            }}
            tick={{ fontSize: isLargerScreen ? "15px" : "10px" }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            width={20}
            tick={{ fontSize: isLargerScreen ? "15px" : "10px" }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            content={<CustomToolTip />}
            allowEscapeViewBox={{
              x: true,
              y: true,
            }}
            cursor={{ fill: "transparent" }}
            offset={-28}
            position={{
              // x: bargraphHeight.x,
              y: bargraphHeight.y - 40,
            }}
          />
          <CartesianGrid
            strokeDasharray="5 5"
            vertical={false}
            horizontal={false}
          />
          <Area
            dataKey="review"
            fill="#7190FF"
            // onMouseOver={(data) => {
            //   setBargraphHeight(data);
            // }}
          >
            {data.map((entry, index) => (
              <Cell
                fill={focusBar === index ? "#7190FF" : "#F0E5FC"}
                key={index}
              />
            ))}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Activities;

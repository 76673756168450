import { GetPromptList, PostAddNewPromptProps } from "../types/types";
import Apimanager from "./axios";

export const getPromptListApi = async (data?: GetPromptList) => {
  const res = await Apimanager.get("/prompts/", {
    params: data,
  });
  return res.data;
};

export const postAddNewPromptApi = async (data: PostAddNewPromptProps) => {
  const res = await Apimanager.post("/prompts/", data);
  return res.data;
};

export const putEditPromptApi = async ({
  id,
  data,
}: {
  id: number;
  data: Partial<PostAddNewPromptProps>;
}) => {
  const res = await Apimanager.put(`/prompts/${id}/`, data);
  return res.data;
};

export const deletePromptApi = async (id: number) => {
  const res = await Apimanager.delete(`/prompts/${id}/`);
  return res.data;
};

export const postSetCurrentApi = async (id: number) => {
  const res = await Apimanager.post(`/prompts/${id}/set_current`);
  return res.data;
};

export const postSetDefaultApi = async (id: number) => {
  const res = await Apimanager.post(`/entity/${id}/use_default_prompt`);
  return res.data;
};

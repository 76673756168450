import { Calendar } from "../ui/calendar";
import { Dialog, DialogContent } from "../ui/dialog";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  date: Date | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

const CalendarDialog = ({
  isOpenDialog,
  setIsOpenDialog,
  date,
  setDate,
}: Props) => {
  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent className="bg-white !rounded-2xl !max-w-fit p-5 lg:p-10">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          disabled={(date) => date < new Date()}
          className="bg-white"
        />
      </DialogContent>
    </Dialog>
  );
};

export default CalendarDialog;

import {
  ChevronDown,
  Pencil,
  Search,
  SquarePlus,
  Trash2,
  X,
} from "lucide-react";
import React, { useRef, useState } from "react";
import { GetPromptListReturn } from "../../types/types";
import AddPromptDialogForUser from "./AddPromptDialogForUser";
import {
  useDeletePrompt,
  useGetPromptList,
  usePostSetCurrent,
  usePostSetDefault,
} from "../../query/usePrompt";
import { generateRandomString, timeAgo } from "../../lib/utils";
import ButtonLoading from "../common/ButtonLoading";
import { toast } from "sonner";
import { AxiosError } from "axios";
import ConfirmDialog from "../common/ConfirmDialog";
import EditPromptDialogForUser from "./EditPromptDialogForUser";

type Props = {
  entityId: number;
};

const Prompt = ({ entityId }: Props) => {
  const [isOpenAddPromptDialog, setIsOpenAddPromptDialog] =
    useState<boolean>(false);
  const [isOpenEditPromptDialog, setIsOpenEditPromptDialog] =
    useState<boolean>(false);
  const [isOpenDeletePromptDialog, setIsOpenDeletePromptDialog] =
    useState<boolean>(false);
  const [deletePromptId, setDeletePromptId] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [toUpdatePrompt, setToUpdatePrompt] = useState<
    GetPromptListReturn | undefined
  >();
  const { data, refetch } = useGetPromptList({ entity_id: entityId });
  const { mutate, isPending } = useDeletePrompt();
  const { mutate: setCurrent, isPending: isSetCurrentLoading } =
    usePostSetCurrent();

  const { mutate: setDefault, isPending: isSetDefaultLoading } =
    usePostSetDefault();

  const handlePromptDelete = () => {
    mutate(deletePromptId, {
      onSuccess: (res) => {
        if (res?.message) {
          toast.success(res?.message);
        } else {
          toast.success("Prompt has been deleted");
        }
        setIsOpenDeletePromptDialog(false);
        refetch();
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      },
    });
  };

  const handleSetCurrent = (id: number) => {
    setCurrent(id, {
      onSuccess: (res) => {
        if (res?.message) {
          toast.success(res?.message);
        } else {
          toast.success("Prompt has been set as current prompt");
        }
        refetch();
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      },
    });
  };

  const handleSetDefault = (id: number) => {
    setDefault(id, {
      onSuccess: (res) => {
        if (res?.message) {
          toast.success(res?.message);
        } else {
          toast.success("Prompt has been set as default prompt");
        }
        refetch();
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      },
    });
  };
  return (
    <>
      <button
        className="w-fit h-12 px-5 flex justify-center items-center gap-x-3 bg-white rounded-lg font-medium text-[#615e83] border-lightpurple hover:border-[#615e83] my-5 mx-5 lg:mx-10"
        onClick={() => setIsOpenAddPromptDialog(true)}
      >
        <p>New Prompt</p>
        <SquarePlus />
      </button>
      <hr />
      <div className="py-5 lg:px-10 px-5 space-y-5">
        <p className="font-semibold text-[22px]">List of Prompts</p>
        <div className="flex items-center gap-x-5">
          <div className="w-full lg:w-[450px] h-10 relative">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full bg-white rounded-lg h-full pl-10"
            />
            <Search className="w-5 h-5 absolute top-1/2 -translate-y-1/2 left-3 text-[#615E83]" />
          </div>
          {/* <button className="text-white bg-primary h-10 rounded-lg px-5 border border-primary hover:bg-white hover:text-primary">
            Search
          </button> */}
        </div>
        <div className="w-full">
          <div className="grid grid-cols-1 gap-5">
            {data
              ?.filter(
                (item) =>
                  item.title.toLowerCase().indexOf(title.toLowerCase()) > -1
              )
              ?.map((item, i) => (
                <div
                  key={i}
                  className="w-full bg-lightpurple rounded-lg p-5 h-fit"
                >
                  <div className="flex items-center justify-between">
                    <p className="font-medium">{item?.title}</p>
                    <div className="flex items-center gap-x-3">
                      <button
                        className="px-3 py-1.5 rounded-lg bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 text-sm"
                        disabled={isSetDefaultLoading}
                        onClick={() => handleSetDefault(item?.id)}
                      >
                        {isSetCurrentLoading ? (
                          <ButtonLoading />
                        ) : (
                          "Set default"
                        )}
                      </button>
                      {item?.is_active ? (
                        <p className="text-green-500 text-sm">Current Prompt</p>
                      ) : (
                        <button
                          className="px-3 py-1.5 rounded-lg bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 text-sm"
                          disabled={isSetCurrentLoading}
                          onClick={() => handleSetCurrent(item?.id)}
                        >
                          {isSetCurrentLoading ? (
                            <ButtonLoading />
                          ) : (
                            "Set current"
                          )}
                        </button>
                      )}
                      <Pencil
                        className="text-[#888] w-5 h-5 cursor-pointer"
                        data-testid="icon-pencil"
                        onClick={() => {
                          setIsOpenEditPromptDialog(true);
                          setToUpdatePrompt(item);
                        }}
                      />
                    </div>
                  </div>
                  <p className="text-sm text-[#4a4a4a] mt-3 line-clamp-3">
                    {item?.context}
                  </p>
                  <div className="flex items-center justify-between text-[#888] mt-5">
                    <div className="flex items-center gap-5 flex-wrap  text-xs">
                      <p>{item?.created_by}</p>
                      <p>
                        Edited{" "}
                        {item?.last_modified && timeAgo(item?.last_modified)}
                      </p>
                    </div>
                    <Trash2
                      className="w-5 h-5 cursor-pointer"
                      data-testid="icon-trash"
                      onClick={() => {
                        setIsOpenDeletePromptDialog(true);
                        setDeletePromptId(item?.id);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <AddPromptDialogForUser
        isOpenDialog={isOpenAddPromptDialog}
        setIsOpenDialog={setIsOpenAddPromptDialog}
        refetch={refetch}
        entityId={entityId}
        key={generateRandomString(5)}
      />
      <EditPromptDialogForUser
        isOpenDialog={isOpenEditPromptDialog}
        setIsOpenDialog={setIsOpenEditPromptDialog}
        refetch={refetch}
        data={toUpdatePrompt}
        key={toUpdatePrompt?.id}
      />
      <ConfirmDialog
        isOpenDialog={isOpenDeletePromptDialog}
        setIsOpenDialog={setIsOpenDeletePromptDialog}
        title="Are you sure you want to delete the prompt?"
        confirmBtnText="Delete"
        confirmAction={handlePromptDelete}
        isPending={isPending}
      />
    </>
  );
};

export default Prompt;

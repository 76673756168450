import { LoginProps, OtpProps, ResetPasswordProps } from "../types/types";
import Apimanager from "./axios";

export const postLoginApi = async (data: LoginProps) => {
  const res = await Apimanager.post("/admin/auth/login", data);
  return res.data;
};

export const postSendCodeApi = async (data: OtpProps) => {
  const res = await Apimanager.post("/admin/auth/send_code", data);
  return res.data;
};

export const postVefrifyCodeApi = async (data: OtpProps) => {
  const res = await Apimanager.post("/admin/auth/verify_code", data);
  return res.data;
};

export const postResetPasswordApi = async (data: ResetPasswordProps) => {
  const res = await Apimanager.post("/admin/auth/reset_password", data);
  return res.data;
};

export const getUserApi = async () => {
  const res = await Apimanager.get("/admin/auth/account");
  return res.data;
};

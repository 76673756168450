import { format } from "date-fns";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Dialog, DialogContent } from "../ui/dialog";

const TimeSelectDropdown = () => {
  const [period, setPeriod] = useState<string>("Today");
  const [openSelectPeriod, setOpenSelectPeriod] = useState<boolean>(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [openDateRange, setOpenDateRange] = useState<boolean>(false);

  return (
    <div className={`relative ${period ? "w-40" : "w-fit"}`}>
      <button
        className="w-full h-10 bg-[#f6f6ff] rounded-lg flex justify-between items-center px-5 text-[#615e83]"
        onClick={() => setOpenSelectPeriod((prev) => !prev)}
      >
        <p className="font-poppins text-sm">
          {period ? (
            period
          ) : (
            <span>
              {date?.[0]?.startDate &&
                format(date?.[0]?.startDate, "dd/MM/yyyy")}{" "}
              {"-"}{" "}
              {date?.[0]?.endDate && format(date?.[0]?.endDate, "dd/MM/yyyy")}
            </span>
          )}
        </p>
        <ChevronDown
          className={`w-5 h-5 ${
            openSelectPeriod && "rotate-180"
          } transition-all duration-200`}
        />
      </button>
      {openSelectPeriod && (
        <>
          <div className="w-full px-5 py-3 rounded-lg absolute top-12 left-0 bg-[#f6f6ff] border text-[#615e83] font-poppins space-y-3 z-50 text-sm text-left">
            {[
              "Today",
              "Yesterday",
              "Last 7 days",
              "Last 30 days",
              "Last 6 months",
              "Last 1 year",
            ].map((item, i) => (
              <p
                className="w-full cursor-pointer"
                onClick={() => setPeriod(item)}
                key={item}
              >
                {item}
              </p>
            ))}
            <hr className="w-full h-1" />
            <p
              className="w-full cursor-pointer"
              onClick={() => {
                setOpenDateRange(true);
                setPeriod("");
              }}
            >
              Custom
            </p>
          </div>
          {
            <Dialog open={openDateRange} onOpenChange={setOpenDateRange}>
              <DialogContent className="flex justify-center items-center bg-white">
                <DateRange
                  editableDateInputs={false}
                  onChange={(item) =>
                    setDate([
                      item.selection as {
                        startDate: Date;
                        endDate: Date;
                        key: string;
                      },
                    ])
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={date}
                />
              </DialogContent>
            </Dialog>
          }
        </>
      )}
    </div>
  );
};

export default TimeSelectDropdown;

import {
  DeleteCouponProps,
  GetCouponProps,
  PostAddCouponProps,
  PostAddPlanProps,
} from "../types/types";
import Apimanager from "./axios";

export const getCouponsApi = async (params: GetCouponProps) => {
  const res = await Apimanager.get("/admin/subscriptions/coupons", {
    params,
  });
  return res.data;
};

export const postAddCouponApi = async (data: PostAddCouponProps) => {
  const res = await Apimanager.post("/admin/subscriptions/coupons", data);
  return res.data;
};

export const deleteCouponApi = async (data: DeleteCouponProps) => {
  const res = await Apimanager.delete("/admin/subscriptions/coupons", {
    data,
  });
  return res.data;
};

export const getPlansApi = async () => {
  const res = await Apimanager.get("/admin/subscriptions/plans");
  return res.data;
};

export const postAddPlansApi = async (data: PostAddPlanProps) => {
  const res = await Apimanager.post("/admin/subscriptions/plans", data);
  return res.data;
};

export const putEditPlansApi = async ({
  id,
  data,
}: {
  id: string;
  data: Partial<PostAddPlanProps>;
}) => {
  const res = await Apimanager.put(`/admin/subscriptions/plans/${id}`, data);
  return res.data;
};

export const deleteDeletePlansApi = async ({
  id,
  password,
}: {
  id: string;
  password: string;
}) => {
  const res = await Apimanager.delete(`/admin/subscriptions/plans/${id}`, {
    data: {
      password: password,
    },
  });
  return res.data;
};

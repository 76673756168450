// import DailyOverview from "../../components/overview/DailyOverview";

import { ArrowBigUp } from "lucide-react";
import ActiveUsers from "../../components/overview/ActiveUsers";
import Activities from "../../components/overview/Activities";
import CustomBox from "../../components/overview/CustomBox";
import RecentSubscriptions from "../../components/overview/RecentSubscriptions";
import Reviews from "../../components/overview/Reviews";
import User from "../../components/overview/User";
import UserChart from "../../components/overview/UserChart";
import VisitByDevice from "../../components/overview/VisitByDevice";

const Overview = () => {
  return (
    <div className="w-full bg-lightpurple min-h-[calc(100vh-140px)] rounded-2xl  p-5 space-y-5 overflow-y-auto font-poppins">
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
        <div className="lg:col-span-4 space-y-5">
          <div className="w-full flex flex-wrap gap-5 justify-between">
            <User />
            <Reviews />
          </div>
          <div className="w-full flex flex-wrap gap-5 justify-between">
            <RecentSubscriptions />
            <Activities />
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-1 gap-5 xl:justify-between w-full">
          <CustomBox
            icon={
              <ArrowBigUp className="w-10 h-10 stroke-1 text-[#07C507] fill-[#0DCF0D]/10" />
            }
            value={"15%"}
            context="Increase in reviews than last month "
          />
          <CustomBox
            icon={
              <ArrowBigUp className="w-10 h-10 stroke-1 text-[#07C507] fill-[#0DCF0D]/10" />
            }
            value={"15%"}
            context="Increase in reviews than last month "
          />
          <CustomBox
            icon={
              <ArrowBigUp className="w-10 h-10 stroke-1 text-[#07C507] fill-[#0DCF0D]/10" />
            }
            value={"15%"}
            context="Increase in reviews than last month "
          />
        </div>
      </div>
      {/* <div className="flex justify-between flex-wrap gap-y-5">
        <UserChart />
        <VisitByDevice />
        <ActiveUsers />
      </div>
      <div className="flex justify-between flex-wrap gap-y-5">
        <RecentSubscriptions />
        <Activities />
      </div> */}
    </div>
  );
};

export default Overview;

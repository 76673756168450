import React from "react";

type Props = {
  value: number | string | undefined;
  setValue: React.Dispatch<React.SetStateAction<number | string | undefined>>;
  title: string;
  placeholder: string;
};

const InputBox = ({ value, setValue, title, placeholder }: Props) => {
  return (
    <div className="flex items-center gap-x-3">
      <div className="w-[100px]">
        <p>{title}</p>
      </div>
      <input
        // type={typeof value === "number" ? "number" : "text"}
        type="text"
        className="w-[160px] rounded-xl border border-[#e6e6e6] px-5 py-2.5 resize-none text-sm"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (typeof value === "number") {
            setValue(Number(e.target.value));
          } else {
            setValue(e.target.value);
          }
        }}
      />
    </div>
  );
};

export default InputBox;

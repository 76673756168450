import { Copy, Filter, Search, SquareMinus, Trash2 } from "lucide-react";
import { useState } from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "../../components/ui/table";
import AddCouponDialog from "../../components/promotion/AddCouponDialog";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import {
  useDeleteCoupon,
  useGetCoupons,
  useGetPlans,
} from "../../query/useSubscription";
import { format } from "date-fns";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "sonner";

const Promotion = () => {
  const [search, setSearch] = useState<string>("");
  const [isOpenAddCouponDialog, setIsOpenAddCouponDialog] =
    useState<boolean>(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [isOpenDisableDialog, setIsOpenDisableDialog] =
    useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);

  const { data: plans } = useGetPlans();

  const { data: coupons, refetch } = useGetCoupons({
    index: index,
  });
  const { mutate: deleteCoupon } = useDeleteCoupon();

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const handleSelectedIds = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedIds((prev) => [...prev, Number(e.target.value)]);
    }
    if (e.target.checked === false) {
      setSelectedIds((prev) =>
        prev.filter((id) => id !== Number(e.target.value))
      );
    }
  };

  const handleDelete = () => {
    deleteCoupon(
      {
        coupon_ids: selectedIds,
      },
      {
        onSuccess: (res: AxiosResponse) => {
          if (res) {
            toast.success("Deleted coupons successfully");
          }
          refetch();
          setIsOpenDeleteDialog(false);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Somethind went wrong!");
            }
          }
        },
      }
    );
  };

  return (
    <div className="font-pro">
      <div className="flex lg:justify-between items-center flex-wrap gap-4">
        <div className="flex items-center gap-4 flex-wrap">
          <div className="relative lg:w-[350px] w-full">
            <input
              type="text"
              placeholder="Search"
              className="w-full rounded-[12px] bg-[#f6f6ff] px-7 py-2.5"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Search
              className="absolute top-1/2 -translate-y-1/2 right-5 w-5 h-5 text-[#615e83]"
              role="button"
              data-testid="search-icon"
            />
          </div>
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
            onClick={() => setIsOpenAddCouponDialog(true)}
          >
            New Coupon
          </button>
          <div className="p-2.5 rounded-[12px] border cursor-pointer">
            <Trash2
              className="w-6 h-6 text-[#525252]"
              onClick={() => setIsOpenDeleteDialog(true)}
              role="button"
              data-testid="delete-icon"
            />
          </div>
        </div>
        <div className="flex items-center gap-4 flex-wrap">
          <button className="border rounded-xl px-5 py-2.5 hover:text-primary hover:border-primary cursor-pointer">
            Active
          </button>
          <button className="border rounded-xl px-5 py-2.5 hover:text-primary hover:border-primary cursor-pointer">
            Expired
          </button>
          <div className="p-2.5 rounded-[12px] cursor-pointer bg-[#f6f6ff]">
            <Filter
              className="w-6 h-6 text-[#525252]"
              role="button"
              data-testid="filter-icon"
            />
          </div>
        </div>
      </div>
      <div className="rounded-lg lg:rounded-2xl border mt-5">
        <Table>
          <TableHeader>
            <TableRow className="text-[#4d4d4d]">
              <TableHead></TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Promo code</TableHead>
              <TableHead>Expiry date</TableHead>
              <TableHead>Date</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Plan</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(coupons?.total_results as number) <= 0 ? (
              <TableRow className="text-[#4a4a4a] text-sm">
                <TableCell colSpan={8}>
                  <p className="text-center">No coupon yet!</p>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {coupons?.results?.map((item) => (
                  <TableRow
                    className={`text-[#4a4a4a] text-sm ${
                      item?.expires &&
                      new Date(item?.expires).getTime() <
                        new Date().getTime() &&
                      "bg-gray-100"
                    }`}
                    key={item.id}
                  >
                    <TableCell>
                      <input
                        type="checkbox"
                        className="cursor-pointer scale-110"
                        value={item.id}
                        onChange={handleSelectedIds}
                      />
                    </TableCell>
                    <TableCell>
                      <p>{item.description}</p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-x-2">
                        <Copy className="w-5 h-5 text-[#9e9e9e]" />
                        <p>{item.code}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-x-2">
                        {item.is_active ? (
                          <div className="w-2 h-2 rounded-full bg-green-500"></div>
                        ) : (
                          <div className="w-2 h-2 rounded-full bg-red-500"></div>
                        )}

                        <p>
                          {item.expires
                            ? format(item.expires, "dd/MM/yyyy")
                            : "No expiration"}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p>
                        {item.created && format(item.created, "dd/MM/yyyy")}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        {item.is_relative
                          ? item.amount + "%"
                          : "AED " + item.amount}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        {item.plan
                          ? plans?.filter((plan) => plan.id === item.plan)?.[0]
                              ?.short_text
                          : "All Plans"}
                      </p>
                    </TableCell>
                    <TableCell>
                      <SquareMinus
                        className="w-5 h-5 text-[#9e9e9e] cursor-pointer"
                        onClick={() => {
                          setIsOpenDisableDialog(true);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      {(coupons?.total_results as number) > 10 && (
        <div className="flex justify-between items-center mt-5">
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 disabled:hidden"
            onClick={handlePrev}
            disabled={index <= 0}
          >
            Prev
          </button>
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 disabled:hidden"
            onClick={handleNext}
            disabled={index + 10 > (coupons?.total_results as number)}
          >
            Next
          </button>
        </div>
      )}

      <AddCouponDialog
        isOpenDialog={isOpenAddCouponDialog}
        setIsOpenDialog={setIsOpenAddCouponDialog}
        refetch={refetch}
      />
      <ConfirmDialog
        isOpenDialog={isOpenDeleteDialog}
        setIsOpenDialog={setIsOpenDeleteDialog}
        title="Are you sure you want to delete this coupon code?"
        confirmAction={handleDelete}
      />
      <ConfirmDialog
        isOpenDialog={isOpenDisableDialog}
        setIsOpenDialog={setIsOpenDisableDialog}
        title="Are you sure you want to disable this coupon code?"
      />
    </div>
  );
};

export default Promotion;

import { ChevronDown } from "lucide-react";
import { useState } from "react";

type CustomDropDownProps = {
  values?: Array<any>;
  value: string | number;
  setValue: React.Dispatch<React.SetStateAction<string | number>>;
  disabled?: boolean;
};

const CustomDropDownForPlans = ({
  values,
  value,
  setValue,
  disabled = false,
}: CustomDropDownProps) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  return (
    <div className={`relative lg:w-[180px] w-full`}>
      <button
        className="w-full h-10 bg-white rounded-lg flex justify-between items-center px-5 text-[#615e83]"
        onClick={() => setOpenDropdown((prev) => !prev)}
        disabled={disabled}
      >
        <p className="text-sm">{value}</p>
        {!disabled && (
          <ChevronDown
            className={`w-5 h-5 ${
              openDropdown && "rotate-180"
            } transition-all duration-200`}
          />
        )}
      </button>
      {openDropdown && (
        <>
          <div className="w-full px-5 py-3 rounded-lg absolute top-10 left-0 bg-[#f7f7f7] border text-[#525252] space-y-3 z-50 text-sm text-left">
            {values.map((item, i) => (
              <p
                className="w-full cursor-pointer hover:text-primary"
                onClick={() => {
                  setValue(item?.uuid ? item?.uuid : item);
                  setOpenDropdown(false);
                }}
                key={item?.uuid || item}
              >
                {item?.short_text
                  ? item?.short_text + " " + item?.currency
                  : item}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomDropDownForPlans;

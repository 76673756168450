import React from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import ButtonLoading from "./ButtonLoading";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  confirmAction?: () => void;
  isPending?: boolean;
};

const ConfirmDialog = ({
  isOpenDialog,
  setIsOpenDialog,
  title,
  confirmBtnText,
  cancelBtnText,
  confirmAction,
  isPending = false,
}: Props) => {
  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent
        className="bg-white !rounded-2xl !lg:max-w-fit max-w-xs p-5 lg:p-10"
        data-testid="confirm-dialog"
      >
        <p className="text-center">{title}</p>
        <div className="flex justify-center items-center gap-x-5">
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
            onClick={confirmAction}
          >
            {isPending ? <ButtonLoading /> : confirmBtnText || "Yes"}
          </button>
          <button
            className="px-7 py-2.5 rounded-[12px]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200"
            onClick={() => setIsOpenDialog(false)}
            data-testid="cancel-confirm-dialog"
          >
            {cancelBtnText || "Cancel"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;

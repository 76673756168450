import { useGetUserCount } from "../../query/useUser";

const User = () => {
  const month = new Date().getMonth();
  const { data } = useGetUserCount();

  return (
    <div className="w-full xl:w-[68.5%] p-5 rounded-[12px] bg-white min-h-max flex flex-col justify-between flex-shrink-0">
      <div className="flex justify-between items-center">
        <p className="text-xl font-medium">Users</p>
        <p className="text-sm font-medium text-[#888]">
          Increase of users by 14.50%{" "}
        </p>
      </div>
      <p className="text-[42px] font-bold leading-[50px]">
        {data?.data?.all_active_users_count?.toLocaleString("en-US")}
      </p>
      <div className="w-full space-y-3">
        <input
          type="range"
          // disabled
          value={month}
          min={0}
          max={11}
          className={`w-full user h-[45px] rounded-[10px]`}
          style={{
            background: `linear-gradient(to right, #7190ff 0%, #7190ff ${
              (month / 11) * 100
            }%, #f6f6f6 ${(month / 11) * 100}%, #f6f6f6 100%)`,
            WebkitAppearance: "none",
            MozAppearance: "none",
            appearance: "none",
          }}
          list="months"
        />
        <datalist id="months" className="flex justify-between w-full">
          {[
            "jan",
            "feb",
            "mar",
            "apr",
            "may",
            "jun",
            "jul",
            "aug",
            "sep",
            "oct",
            "nov",
            "dec",
          ].map((item, i) => (
            <option
              key={item}
              className="text-xs text-[#888] uppercase"
              value={i}
              label={item}
            ></option>
          ))}
        </datalist>
      </div>
    </div>
  );
};

export default User;

import { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Settings = () => {
  const [editorState, setEditorState] = useState({
    editorState: EditorState.createEmpty(),
  });
  // console.log(
  //   draftToHtml(convertToRaw(editorState.editorState.getCurrentContent()))
  // );
  return (
    <>Settings</>
    // <Editor
    //   wrapperClassName="border"
    //   editorClassName="p-5"
    //   editorState={editorState.editorState}
    //   onEditorStateChange={(editorState) => setEditorState({ editorState })}
    //   toolbar={{
    //     image: {
    //       uploadCallback: (file) => {
    //         return new Promise((resolve, reject) => {
    //           const reader = new FileReader();
    //           reader.readAsDataURL(file);
    //           reader.onload = () => {
    //             const dataURL = reader.result;
    //             // const truncatedDataURL = (dataURL as string)?(10, 30) + "..."; // set the maximum length of the truncated string
    //             resolve({ data: { link: dataURL } });
    //           };
    //           reader.onerror = (error) => {
    //             reject(error);
    //           };
    //         });
    //       },
    //       alt: { present: true, mandatory: false },
    //       previewImage: true,
    //       urlEnabled: true,
    //       uploadEnabled: true,
    //       alignmentEnabled: true,
    //     },
    //     list: {
    //       inDropdown: false,
    //       className: undefined,
    //       component: undefined,
    //       dropdownClassName: undefined,
    //       options: ["unordered", "ordered", "indent", "outdent"],
    //     },
    //   }}
    // />
  );
};

export default Settings;

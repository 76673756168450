import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetUserReturn,
  LoginProps,
  OtpProps,
  ResetPasswordProps,
} from "../types/types";
import {
  getUserApi,
  postLoginApi,
  postResetPasswordApi,
  postSendCodeApi,
  postVefrifyCodeApi,
} from "../api/auth";

export const usePostLogin = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: (data: LoginProps) => postLoginApi(data),
  });
};

export const usePostSendCode = () => {
  return useMutation({
    mutationKey: ["sendCode"],
    mutationFn: (data: OtpProps) => postSendCodeApi(data),
  });
};

export const usePostVerifyCode = () => {
  return useMutation({
    mutationKey: ["verifyCode"],
    mutationFn: (data: OtpProps) => postVefrifyCodeApi(data),
  });
};

export const usePostResetPassword = () => {
  return useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: (data: ResetPasswordProps) => postResetPasswordApi(data),
  });
};

export const useGetUser = () => {
  return useQuery<GetUserReturn>({
    queryKey: ["getUser"],
    queryFn: () => getUserApi(),
  });
};

import { ReactNode } from "react";

type Props = {
  icon: ReactNode;
  value: string;
  context: string;
};
const CustomBox = ({ icon, value, context }: Props) => {
  return (
    <div className="w-full px-5 py-7 rounded-[12px] bg-white min-h-max shrink-0 col-span-1">
      <div className="flex justify-end w-full">{icon}</div>
      <p className="font-semibold text-[30px]">{value}</p>
      <p className="font-medium">{context}</p>
    </div>
  );
};

export default CustomBox;

import { ChevronDown } from "lucide-react";
import { useState } from "react";

type Props = {
  value: string | number;
  setValue: React.Dispatch<React.SetStateAction<string | number>>;
};

const DropDownForDuration = ({ value, setValue }: Props) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  return (
    <div className={`relative w-[160px]`}>
      <button
        className="w-full h-10 bg-[#f7f7f7] rounded-lg flex justify-between items-center px-5 text-[#615e83]"
        onClick={() => setOpenDropdown((prev) => !prev)}
      >
        <p className="text-sm capitalize">{value}</p>
        <ChevronDown
          className={`w-5 h-5 ${
            openDropdown && "rotate-180"
          } transition-all duration-200`}
        />
      </button>
      {openDropdown && (
        <>
          <div className="w-full px-5 py-3 rounded-lg absolute top-12 left-0 bg-[#f7f7f7] border text-[#525252] space-y-3 z-50 text-sm text-left">
            {["days", "months", "years"].map((item, i) => (
              <p
                className="w-full cursor-pointer capitalize"
                onClick={() => {
                  setValue(item);
                  setOpenDropdown(false);
                }}
                key={item}
              >
                {item}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DropDownForDuration;

import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import InputBox from "./InputBox";
import { usePostAddPlans, usePutEditPlans } from "../../query/useSubscription";
import ButtonLoading from "../common/ButtonLoading";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "sonner";
import { GetPlansReturn } from "../../types/types";
import DropDownForDuration from "./DropDownForDuration";
import PasswordModal from "../users/PasswordModal";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  data: GetPlansReturn;
};

const EditPlanDialog = ({
  isOpenDialog,
  setIsOpenDialog,
  refetch,
  data,
}: Props) => {
  const [price, setPrice] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [planDuration, setPlanDuration] = useState<string>("");
  const [stripeProductId, setStripeProductId] = useState<string>("");
  const [unitMultiplier, setUnitMultiplier] = useState<string>("");
  const [secondaryText, setSecondaryText] = useState<string>("");
  const [discount, setDiscount] = useState<string>("");
  const [isRelativeDiscount, setIsRelativeDiscount] = useState<boolean>(false);
  const [duration, setDuration] = useState<string>("");
  const [durationText, setDurationText] = useState<string>("days");
  const [isOpenPassword, setIsOpenPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { mutate: editPlan, isPending } = usePutEditPlans();

  const handleEditPlan = () => {
    setPasswordError("");
    if (price === undefined) {
      toast.error("Price is required");
      return;
    }
    if (!currency) {
      toast.error("Currency is required");
      return;
    }
    if (!secondaryText) {
      toast.error("Secondary text is required");
      return;
    }
    if (!planDuration) {
      toast.error("Short text is required");
      return;
    }
    if (!stripeProductId) {
      toast.error("Product Id is required");
      return;
    }
    editPlan(
      {
        id: data?.uuid,
        data: {
          price: Number(price),
          currency: currency,
          short_text: planDuration,
          secondary_text: secondaryText,
          stripe_product_id: stripeProductId,
          currency_unit_multiplier: Number(unitMultiplier),
          discount: Number(discount),
          is_relative_discount_price: isRelativeDiscount,
          is_popular: true,
          dt: durationText,
          dq: Number(duration),
          password: password,
        },
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("Updated plan successfully");
          }
          refetch();
          setIsOpenDialog(false);
          setIsOpenPassword(false);
          setPassword("");
          setPasswordError("");
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.status === "field error") {
              if (err.response.data.message) {
                setPasswordError(err.response.data.message?.password?.[0]);
              }
            } else {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      const duration = data?.duration.split("");
      setPrice(String(data?.price));
      setCurrency(data?.currency);
      setPlanDuration(data?.short_text);
      setStripeProductId(data?.stripe_product_id);
      setUnitMultiplier(String(data?.currency_unit_multiplier));
      setSecondaryText(data?.secondary_text);
      setDiscount(String(data?.discount));
      setIsRelativeDiscount(data?.is_relative_discount_price);
      setDuration(String(data?.duration?.match(/\d+/)[0]));
      if (duration?.[duration?.length - 1] === "D") {
        setDurationText("days");
      } else if (duration?.[2] === "M") {
        setDurationText("months");
      } else {
        setDurationText("years");
      }
    }
  }, [data]);

  return (
    <>
      <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
        <DialogContent
          className="bg-white !rounded-2xl !max-w-fit p-5 lg:p-10"
          data-testid="add-plan-dialog"
        >
          <div className="flex justify-between items-center gap-5 lg:gap-10 flex-wrap">
            <InputBox
              value={price}
              setValue={setPrice}
              title="Price:"
              placeholder="e.g. 290"
            />
            <InputBox
              value={currency}
              setValue={setCurrency}
              title="Currency:"
              placeholder="e.g. AED"
            />
          </div>
          <div className="flex justify-between items-center gap-5 flex-wrap">
            <InputBox
              value={planDuration}
              setValue={setPlanDuration}
              title="Plan:"
              placeholder="e.g. 6 Months"
            />
            <InputBox
              value={secondaryText}
              setValue={setSecondaryText}
              title="Secondary Text:"
              placeholder="e.g. Most popular"
            />
          </div>
          <div className="flex justify-between items-center gap-5 flex-wrap">
            <InputBox
              value={stripeProductId}
              setValue={setStripeProductId}
              title="Product Id:"
              placeholder="e.g. prod_Uiv142ie"
            />
            <InputBox
              value={unitMultiplier}
              setValue={setUnitMultiplier}
              title="Currency Unit Multiplier:"
              placeholder="e.g. 100"
            />
          </div>
          <div className="flex items-center gap-5 flex-wrap">
            <InputBox
              value={duration}
              setValue={setDuration}
              title="Duration:"
              placeholder="e.g. 3"
            />
            <DropDownForDuration
              value={durationText}
              setValue={setDurationText}
            />
          </div>
          <div className="flex justify-between items-center gap-5 flex-wrap">
            <InputBox
              value={discount}
              setValue={setDiscount}
              title="Discount:"
              placeholder="e.g. 300"
            />
            <div className="flex items-center gap-x-3 ">
              <input
                type="checkbox"
                checked={isRelativeDiscount}
                id="relative"
                onChange={() => setIsRelativeDiscount((prev) => !prev)}
              />
              <label htmlFor="relative">Relative Discount</label>
            </div>
          </div>
          <div className="flex justify-center items-center gap-x-5 flex-wrap mt-10">
            <button
              className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
              onClick={() => {
                setIsOpenPassword(true);
              }}
            >
              {"Update Plan"}
            </button>
            <button
              className="px-7 py-2.5 rounded-[12px]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200"
              onClick={() => setIsOpenDialog(false)}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <PasswordModal
        isOpenDialog={isOpenPassword}
        setIsOpenDialog={setIsOpenPassword}
        confirmAction={handleEditPlan}
        isLoading={isPending}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
    </>
  );
};

export default EditPlanDialog;

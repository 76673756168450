import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const Reviews = () => {
  const data = [
    { name: "A", value: 500, color: "#7190FF" },
    { name: "B", value: 300, color: "#F0E5FC" },
  ];
  return (
    <div className="w-full xl:w-[29%] p-5 rounded-[12px] bg-white min-h-max flex-shrink-0">
      <div className="flex justify-between items-center">
        <p className=" text-xl font-medium">Reviews</p>
      </div>
      <div className="w-full relative">
        <ResponsiveContainer
          width="100%"
          height={140}
          className="w-full xl:scale-110"
        >
          <PieChart className="my-0 lg:mt-10 mx-auto">
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data}
              cx={"50%"}
              cy={"75%"}
              innerRadius={60}
              outerRadius={100}
              fill="#7190FF"
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="flex flex-col justify-center items-center absolute top-1/2 -translate-y-[0%] left-1/2 -translate-x-1/2">
          <p className="font-semibold text-[28px]">565</p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <div className="w-[10px] h-[10px] rounded-full bg-primary"></div>
        <p className="font-light text-xs text-[#888]">
          Total no. of reviews posted today
        </p>
      </div>
    </div>
  );
};

export default Reviews;

import React from "react";

type Props = {
  label: string;
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
};

const Input = ({ label, placeholder, value, setValue, disabled }: Props) => {
  return (
    <div className="w-full">
      <label className="text-[#000] text-lg font-pro font-medium mb-[10px] flex gap-1">
        {label}
      </label>
      <input
        className="border-[1px] border-[#E7E4E4] rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5]"
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default Input;

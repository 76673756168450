import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deletePromptApi,
  getPromptListApi,
  postAddNewPromptApi,
  postSetCurrentApi,
  postSetDefaultApi,
  putEditPromptApi,
} from "../api/prompt";
import {
  GetPromptList,
  GetPromptListReturn,
  PostAddNewPromptProps,
} from "../types/types";

export const useGetPromptList = (data?: GetPromptList) => {
  return useQuery<Array<GetPromptListReturn>>({
    queryKey: ["getPromptList", data],
    queryFn: () => getPromptListApi(data),
  });
};

export const usePostAddNewPrompt = () => {
  return useMutation({
    mutationKey: ["postAddNewPrompt"],
    mutationFn: (data: PostAddNewPromptProps) => postAddNewPromptApi(data),
  });
};

export const usePutEditPrompt = () => {
  return useMutation({
    mutationKey: ["putEditPrompt"],
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: Partial<PostAddNewPromptProps>;
    }) => putEditPromptApi({ id, data }),
  });
};

export const useDeletePrompt = () => {
  return useMutation({
    mutationKey: ["deletePrompt"],
    mutationFn: (id: number) => deletePromptApi(id),
  });
};

export const usePostSetCurrent = () => {
  return useMutation({
    mutationKey: ["postSetCurrent"],
    mutationFn: (id: number) => postSetCurrentApi(id),
  });
};

export const usePostSetDefault = () => {
  return useMutation({
    mutationKey: ["postDefaultPrompt"],
    mutationFn: (id: number) => postSetDefaultApi(id),
  });
};

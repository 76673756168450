import { Link, Mail, User } from "lucide-react";
import { useState } from "react";
import Account from "./Account";
import Subscription from "./Subscription";
import Questions from "./Questions";
import Chat from "./Chat";
import { GetUserListReturn } from "../../types/types";
import Prompt from "./Prompt";
import Overview from "./Overview";

const UserDetails = ({ user }: { user: GetUserListReturn }) => {
  const [selectedTab, setSelectedTab] = useState("overview");

  return (
    <div className="w-full px-5 pb-5 font-pro">
      <div className="flex items-center gap-x-5 overflow-x-auto scrollbar-none bg-white rounded-xl px-5 py-1">
        {["overview", "account", "subscription", "prompt"].map((item) => (
          <div
            key={item}
            onClick={() => setSelectedTab(item)}
            className={`capitalize text-sm py-2 px-5 cursor-pointer text-center rounded-lg ${
              selectedTab === item ? "bg-primary text-white" : "text-[#232525]"
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <div className="min-h-[calc(100vh-280px)] max-h-[calc(100vh-280px)] overflow-y-auto scrollbar-thin">
        {!user && (
          <p className="text-center mt-10 text-[#888] text-sm">No user!</p>
        )}
        {selectedTab === "overview" && user && (
          <Overview
            profile={user?.profile}
            entity={user?.entity}
            first_name={user?.first_name}
            last_name={user?.last_name}
            mail={user?.email}
            key={user?.id}
          />
        )}
        {selectedTab === "account" && user && (
          <Account
            profile={user?.profile}
            entity={user?.entity}
            first_name={user?.first_name}
            last_name={user?.last_name}
            mail={user?.email}
            key={user?.id}
          />
        )}
        {selectedTab === "subscription" && user && (
          <Subscription
            profile={user?.profile}
            key={user?.id}
            email={user?.email}
          />
        )}
        {/* {selectedTab === "questions" && user && <Questions />}
        {selectedTab === "chat" && user && <Chat />} */}
        {selectedTab === "prompt" && user && (
          <Prompt entityId={user?.entity?.id} />
        )}
      </div>
    </div>
  );
};

export default UserDetails;

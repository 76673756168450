import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "/magicreview.svg";
import {
  AlignJustify,
  ChevronDown,
  CreditCard,
  LayoutGrid,
  LogOut,
  MessageSquareText,
  Settings,
  Tags,
  Users,
  X,
} from "lucide-react";
import { useState } from "react";
import { useGetUser } from "../../query/useAuth";
import { cn } from "../../lib/utils";
const RootLayout = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [isOpenNav, setIsOpenNav] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const { data: user } = useGetUser();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="w-full flex min-h-screen ">
      <div
        className={`${
          isCollapsed ? "w-20" : "w-[19%]"
        } hidden lg:block bg-[#f6f6ff] min-h-screen transition-all duration-300`}
      >
        <div className="flex flex-col justify-between h-full">
          <div className={cn(isCollapsed ? "px-5" : "px-12")}>
            <div className="flex items-center gap-x-2 w-[180px] pt-5">
              <img
                src={Logo}
                alt="MagicReview Logo"
                className="w-[54px] -ml-2"
              />
              {isCollapsed ? null : (
                <h1 className="font-pro font-bold text-[21px]">magicreview</h1>
              )}
            </div>
            <div className="space-y-1 mt-5">
              {!isCollapsed && (
                <p className="font-montserrat font-semibold text-sm text-[#252323] py-3">
                  MAIN MENU
                </p>
              )}
              <Link
                to={"/"}
                className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins ${
                  !isCollapsed ? "pl-3" : "px-2"
                } py-2 ${
                  pathname === "/"
                    ? "bg-[#DADFFF] font-medium text-[#2A49B8]"
                    : "text-[#3d3d3d]"
                }`}
                title="Overview"
              >
                <LayoutGrid className="w-6 h-6" />
                {!isCollapsed && "Overview"}
              </Link>
              <Link
                to={"/users"}
                className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins ${
                  !isCollapsed ? "pl-3" : "px-2"
                } py-2 ${
                  pathname === "/users"
                    ? "bg-[#DADFFF] font-medium text-[#2A49B8]"
                    : "text-[#3d3d3d]"
                }`}
                title="Users"
              >
                <Users className="w-6 h-6" />
                {!isCollapsed && "Users"}
              </Link>
              <Link
                to={"/prompt"}
                className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins ${
                  !isCollapsed ? "pl-3" : "px-2"
                } py-2 ${
                  pathname === "/prompt"
                    ? "bg-[#DADFFF] font-medium text-[#2A49B8]"
                    : "text-[#3d3d3d]"
                }`}
                title="Prompt"
              >
                <MessageSquareText className="w-6 h-6" />
                {!isCollapsed && "Prompt"}
              </Link>
              <Link
                to={"/settings"}
                className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins ${
                  !isCollapsed ? "pl-3" : "px-2"
                } py-2 ${
                  pathname.includes("settings")
                    ? "bg-[#DADFFF] font-medium text-[#2A49B8]"
                    : "text-[#3d3d3d]"
                }`}
                title="Settings"
              >
                <Settings className="w-6 h-6" />
                {!isCollapsed && "Settings"}
              </Link>
            </div>
            <div className="space-y-1">
              {!isCollapsed && (
                <p className="font-montserrat font-semibold text-sm text-[#252323] py-3">
                  SUBSCRIPTION
                </p>
              )}
              <Link
                to={"/promotion"}
                className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins ${
                  !isCollapsed ? "pl-3" : "px-2 mt-1"
                } py-2 ${
                  pathname.includes("promotion")
                    ? "bg-[#DADFFF] font-medium text-[#2A49B8]"
                    : "text-[#3d3d3d]"
                }`}
                title="Promotion"
              >
                <Tags className="w-6 h-6" />
                {!isCollapsed && "Promotion"}
              </Link>
              <Link
                to={"/plans"}
                className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins ${
                  !isCollapsed ? "pl-3" : "px-2"
                } py-2 ${
                  pathname.includes("plans")
                    ? "bg-[#DADFFF] font-medium text-[#2A49B8]"
                    : "text-[#3d3d3d]"
                }`}
                title="Plans"
              >
                <CreditCard className="w-6 h-6" />
                {!isCollapsed && "Plans"}
              </Link>
            </div>
          </div>
          <div>
            {isCollapsed ? (
              <div className="flex flex-col items-center justify-center">
                <img
                  src={user?.profile || Logo}
                  alt=""
                  className="w-10 h-10 rounded-full cursor-pointer"
                  referrerPolicy="no-referrer"
                  title={user?.first_name + " " + user?.last_name}
                />
                <button
                  className=" hover:text-primary cursor-pointer py-5 text-[#3d3d3d] font-montserrat"
                  onClick={handleLogout}
                  title="Log out"
                >
                  <LogOut className="w-[18px] h-[18px]" />
                </button>
              </div>
            ) : (
              <>
                <div className="flex items-center pl-11 py-5 gap-x-3 border-t w-full">
                  <img
                    src={user?.profile || Logo}
                    alt=""
                    className="w-[34px] h-[34px] rounded-full"
                    referrerPolicy="no-referrer"
                  />
                  <div className="">
                    <p className="font-pro font-medium line-clamp-1 w-full">
                      {user?.first_name} {user?.last_name}
                    </p>
                    <p className="font-pro text-xs text-[#888] line-clamp-1 w-full">
                      MagicReview Admin
                    </p>
                  </div>
                  {/* <ChevronDown className="text-[#888] w-4 h-4" /> */}
                </div>
                <button
                  className="flex items-center gap-x-3 hover:text-primary border-t px-12 cursor-pointer py-5 w-full text-[#3d3d3d] font-montserrat"
                  onClick={handleLogout}
                >
                  <LogOut className="w-[18px] h-[18px]" />
                  <p className="font-pro font-medium">Logout</p>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          " lg:pb-8 pb-5 w-full relative min-h-screen max-h-screen overflow-y-auto",
          isCollapsed ? "lg:w-[calc(100vw-80px)]" : "lg:w-[81%]"
        )}
      >
        <div className="flex justify-between items-center lg:py-8 py-5 px-5 sticky top-0 z-50 bg-white">
          <p className="font-poppins font-medium text-2xl">
            {pathname.includes("settings")
              ? "Settings"
              : pathname.includes("promotion")
              ? "Promotion"
              : pathname.includes("users")
              ? "Users"
              : pathname.includes("prompt")
              ? "Prompt"
              : pathname.includes("plans")
              ? "Plans"
              : "Overview"}
          </p>
          {isOpenNav ? (
            <X
              className="w-7 h-7 lg:hidden"
              onClick={() => setIsOpenNav(false)}
            />
          ) : (
            <AlignJustify
              className="w-7 h-7 lg:hidden"
              onClick={() => setIsOpenNav(true)}
            />
          )}
        </div>
        {isOpenNav && (
          <div className="fixed top-0 left-0 z-50 w-3/5 min-h-screen shadow-md bg-white p-3">
            <div className="flex justify-center items-center gap-x-2 w-[180px]">
              <img src={Logo} alt="MagicReview Logo" className="w-[54px]" />
              <h1 className="font-pro font-bold text-[21px]">magicreview</h1>
            </div>
            <div className="flex flex-col justify-between h-[calc(100vh-100px)] mt-5 px-2">
              <div className="">
                <div className="space-y-1 mt-5">
                  <p className="font-montserrat font-semibold text-sm text-[#252323] py-3">
                    MAIN MENU
                  </p>
                  <Link
                    to={"/"}
                    className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins pl-3 py-2 ${
                      pathname === "/"
                        ? "bg-[#DADFFF] font-medium"
                        : "text-[#3d3d3d]"
                    }`}
                  >
                    <LayoutGrid className="w-6 h-6" />
                    Overview
                  </Link>
                  <Link
                    to={"/users"}
                    className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins pl-3 py-2 ${
                      pathname === "/users"
                        ? "bg-[#DADFFF] font-medium"
                        : "text-[#3d3d3d]"
                    }`}
                  >
                    <Users className="w-6 h-6" />
                    Users
                  </Link>
                  <Link
                    to={"/prompt"}
                    className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins pl-3 py-2 ${
                      pathname === "/prompt"
                        ? "bg-[#DADFFF] font-medium"
                        : "text-[#3d3d3d]"
                    }`}
                  >
                    <MessageSquareText className="w-6 h-6" />
                    Prompt
                  </Link>
                  <Link
                    to={"/settings"}
                    className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins pl-3 py-2 ${
                      pathname.includes("settings")
                        ? "bg-[#DADFFF] font-medium"
                        : "text-[#3d3d3d]"
                    }`}
                  >
                    <Settings className="w-6 h-6" />
                    Settings
                  </Link>
                </div>
                <div className="space-y-1">
                  <p className="font-montserrat font-semibold text-sm text-[#252323] py-3">
                    SUBSCRIPTION
                  </p>
                  <Link
                    to={"/promotion"}
                    className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins pl-3 py-2 ${
                      pathname.includes("promotion")
                        ? "bg-[#DADFFF] font-medium"
                        : "text-[#3d3d3d]"
                    }`}
                  >
                    <Tags className="w-6 h-6" />
                    Promotion
                  </Link>
                  <Link
                    to={"/plans"}
                    className={`flex items-center gap-x-3 rounded-md hover:font-medium hover:bg-[#DADFFF] font-poppins pl-3 py-2 ${
                      pathname.includes("plans")
                        ? "bg-[#DADFFF] font-medium"
                        : "text-[#3d3d3d]"
                    }`}
                  >
                    <CreditCard className="w-6 h-6" />
                    Plans
                  </Link>
                </div>
              </div>
              <div>
                <div className="flex items-center py-5 gap-x-3 border-t">
                  <img
                    src={Logo}
                    alt=""
                    className="w-[34px] h-[34px] rounded-full"
                  />
                  <div>
                    <p className="font-pro font-medium">Riyo Marry</p>
                    <p className="font-pro text-xs text-[#888]">
                      MagirReview Admin
                    </p>
                  </div>
                  <ChevronDown className="text-[#888] w-4 h-4" />
                </div>
                <button
                  className="flex items-center pt-5 gap-x-3 text-[#8c8c8c] hover:text-primary border-t  cursor-pointer"
                  onClick={handleLogout}
                >
                  <LogOut className="w-[18px] h-[18px]" />
                  <p className="font-pro font-medium text-xs">Logout</p>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="px-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default RootLayout;

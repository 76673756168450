import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import { CalendarDays, RefreshCw } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { format, formatISO } from "date-fns";
import { Calendar } from "../ui/calendar";
import { generateRandomString } from "../../lib/utils";
import { useGetPlans, usePostAddCoupon } from "../../query/useSubscription";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "sonner";
import ButtonLoading from "../common/ButtonLoading";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
};

const AddCouponDialog = ({ isOpenDialog, setIsOpenDialog, refetch }: Props) => {
  const [couponCode, setCouponCode] = useState<string>("");
  const [date, setDate] = useState<Date | undefined>();
  const [plan, setPlan] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [priceType, setPriceType] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);

  const { data: plans, isSuccess } = useGetPlans();
  const { mutate: addCoupon, isPending } = usePostAddCoupon();

  const handleGenerateCode = () => {
    const randomCode = generateRandomString(10);
    setCouponCode(randomCode);
  };

  const handleAddCoupon = () => {
    addCoupon(
      {
        code: couponCode,
        expires: date ? formatISO(date as Date).split("+")?.[0] : null,
        plan: plan === 0 ? null : plan,
        description: description,
        tag: "marketing",
        is_relative: priceType === "percent" ? true : false,
        amount: amount,
      },
      {
        onSuccess: (res: AxiosResponse) => {
          if (res) {
            toast.success("Added coupon successfully");
          }
          refetch();
          setIsOpenDialog(false);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.data.code) {
              toast.error(err.response?.data.code[0]);
            } else if (err.response?.data.expires) {
              toast.error(err.response?.data.expires[0]);
            } else if (err.response?.data.plan) {
              toast.error(err.response?.data.plan[0]);
            } else if (err.response?.data.description) {
              toast.error(err.response?.data.description[0]);
            } else if (err.response?.data.tag) {
              toast.error(err.response?.data.tag[0]);
            } else if (err.response?.data.is_relative) {
              toast.error(err.response?.data.is_relative[0]);
            } else if (err.response?.data.amount) {
              toast.error(err.response?.data.amount[0]);
            } else if (err.response?.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          }
        },
      }
    );
  };

  useEffect(() => {
    if (plans && isSuccess) {
      setPlan(plans?.[0]?.id);
    }
  }, [plans, isSuccess]);

  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent
        className="bg-white !rounded-2xl !max-w-fit p-5 lg:p-10"
        data-testid="add-coupon-dialog"
      >
        <div className="flex items-center gap-x-3">
          <div className="w-[100px]">
            <p>Coupon code:</p>
          </div>
          <div className="flex items-center gap-x-2">
            <input
              type="text"
              className="rounded-[12px] bg-[#f6f6ff] px-5 py-2.5 w-[140px] text-sm"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <div
              className="p-2.5 rounded-[12px] cursor-pointer bg-[#f6f6ff] active:rotate-90 transition-all duration-200"
              onClick={handleGenerateCode}
            >
              <RefreshCw className="w-6 h-6 text-[#525252]" />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-5 flex-wrap ">
          <div className="flex items-center gap-x-3">
            <div className="w-[100px]">
              <p>Expiry Date:</p>
            </div>
            <div className="flex items-center gap-x-2">
              <Popover>
                <PopoverTrigger asChild>
                  <div className="w-[190px] relative">
                    <input
                      type="text"
                      value={date ? format(date as Date, "dd/MM/yyyy") : ""}
                      className="w-full border rounded-xl border-[#e6e6e6] px-5 py-2.5 text-sm"
                      readOnly
                    />
                    <CalendarDays className="w-5 h-5 absolute right-5 top-1/2 -translate-y-1/2 text-[#888]" />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="bg-white !w-full">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    disabled={(date) => date < new Date()}
                    className="bg-white"
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className="flex items-center gap-x-3">
            <div className="w-[50px]">
              <p>Plan:</p>
            </div>
            <div className="flex items-center gap-x-2">
              <select
                className="border rounded-xl border-[#e6e6e6] px-5 py-2.5 w-[200px] text-sm"
                value={plan}
                onChange={(e) => setPlan(Number(e.target.value))}
              >
                <option value={0}>All Plans</option>
                {plans?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.currency +
                      " " +
                      item.price +
                      " " +
                      "(" +
                      item.short_text +
                      ")"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex items-start gap-5 flex-wrap">
          <div className="flex items-start gap-x-3">
            <div className="w-[100px]">
              <p>Description:</p>
            </div>
            <div className="w-[190px]">
              <textarea
                className="w-full rounded-xl border border-[#e6e6e6] px-5 py-2.5 resize-none text-sm"
                placeholder="Enter code description"
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-start gap-x-3">
            <div className="w-[50px]">
              <p>Price:</p>
            </div>
            <div className="space-y-3">
              <div className="flex items-center gap-x-3">
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="price"
                    id="percent"
                    className="scale-125"
                    value={"percent"}
                    onChange={(e) => setPriceType(e.target.value)}
                  />
                  <label htmlFor="percent">Percent</label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="price"
                    id="amount"
                    className="scale-125"
                    value={"amount"}
                    onChange={(e) => setPriceType(e.target.value)}
                  />
                  <label htmlFor="amount">Amount</label>
                </div>

                {/* <input
                  type="text"
                  className="w-[160px] rounded-xl border border-[#e6e6e6] px-5 py-2.5 resize-none text-sm"
                  placeholder="e.g. AED 40 off"
                /> */}
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  type="text"
                  className="w-[160px] rounded-xl border border-[#e6e6e6] px-5 py-2.5 resize-none text-sm"
                  placeholder={
                    priceType === "percent" ? "e.g. 30% off" : "e.g. AED 40 off"
                  }
                  value={amount || ""}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center gap-x-5 flex-wrap mt-10">
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
            onClick={handleAddCoupon}
          >
            {isPending ? <ButtonLoading /> : "Add Coupon"}
          </button>
          <button
            className="px-7 py-2.5 rounded-[12px]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200"
            onClick={() => setIsOpenDialog(false)}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddCouponDialog;

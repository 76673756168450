import { CircleAlert } from "lucide-react";
import ButtonLoading from "../common/ButtonLoading";
import { Dialog, DialogContent } from "../ui/dialog";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  confirmAction: () => void;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  passwordError: string;
  setPasswordError: React.Dispatch<React.SetStateAction<string>>;
};

const PasswordModal = ({
  isOpenDialog,
  setIsOpenDialog,
  confirmAction,
  value,
  setValue,
  isLoading,
  passwordError,
  setPasswordError,
}: Props) => {
  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent
        className="bg-white !rounded-2xl !lg:max-w-fit max-w-xs p-5 lg:p-10 font-poppins"
        data-testid="add-prompt-dialog"
      >
        <p className="font-semibold text-[22px]">Confirm Password</p>
        <div className="w-full">
          <input
            type="text"
            className={`w-full rounded-lg bg-[#7d7a9a]/5 px-5 py-2 border  ${
              passwordError ? "border-red-500" : "border-[#7d7a9a]/35"
            }`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter password"
          />
          {passwordError && (
            <p className="flex items-center gap-x-1 text-red-500 text-xs mt-0.5">
              <CircleAlert className="w-4 h-4" />
              {passwordError}
            </p>
          )}
        </div>

        <div className="flex flex-wrap justify-center items-center gap-5 lg:gap-x-10">
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 w-full"
            onClick={confirmAction}
          >
            {isLoading ? <ButtonLoading /> : "Confirm password"}
          </button>
          <button
            className="px-7 py-2.5 rounded-[12px] bg-lightpurple text-[#7D7A9A]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200 w-full"
            onClick={() => {
              setIsOpenDialog(false);
              setPasswordError("");
            }}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordModal;

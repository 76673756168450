import {
  PostUserCreateProps,
  PostUserSuspendProps,
  PutUserPlanUpdateProps,
  PutUserSubscriptionUpdateProps,
} from "../types/types";
import Apimanager from "./axios";

export const getUserListApi = async ({
  index,
  email,
}: {
  index: number;
  email?: string;
}) => {
  const params = {
    index: index,
    email: email,
  };
  if (!email) {
    delete params["email"];
  }
  const res = await Apimanager.get(`/admin/user_management/search`, {
    params: params,
  });
  return res.data;
};

export const getUserCountApi = async () => {
  const res = await Apimanager.get(`/admin/generic/user_count`);
  return res.data;
};

export const getRecentSunscriptionApi = async () => {
  const res = await Apimanager.get(`/admin/generic/recent_subscription`);
  return res.data;
};

export const postUserCreateApi = async (data: PostUserCreateProps) => {
  const res = await Apimanager.post(`/admin/user_management/create`, data);
  return res.data;
};

export const postUserSuspendApi = async (data: PostUserSuspendProps) => {
  const res = await Apimanager.post(`/admin/user_management/ban`, data);
  return res.data;
};

export const putUserUpdateApi = async (data: Partial<PostUserCreateProps>) => {
  const res = await Apimanager.put(`/admin/user_management/update`, data);
  return res.data;
};

export const putUserSubscriptionUpdateApi = async (
  data: PutUserSubscriptionUpdateProps
) => {
  const res = await Apimanager.put(
    `/admin/user_management/update/subscription_details`,
    data
  );
  return res.data;
};

export const postAddUserPlanApi = async (
  data: Partial<PutUserPlanUpdateProps>
) => {
  const res = await Apimanager.post(
    `/admin/user_management/${data?.email}/subscription_add`,
    {
      plan: data?.plan,
      auto_renew: data?.auto_renew,
      password: data?.password,
    }
  );
  return res.data;
};

export const putUserPlanUpdateApi = async (
  data: Partial<PutUserPlanUpdateProps>
) => {
  const res = await Apimanager.put(
    `/admin/user_management/${data?.email}/subscription_update_delete`,
    {
      plan: data?.plan,
      auto_renew: data?.auto_renew,
      password: data?.password,
    }
  );
  return res.data;
};

export const deleteUserPlanApi = async (
  data: Partial<PutUserPlanUpdateProps>
) => {
  const res = await Apimanager.delete(
    `/admin/user_management/${data?.email}/subscription_update_delete`,
    {
      data: {
        password: data?.password,
      },
    }
  );
  return res.data;
};

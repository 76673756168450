/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  usePostResetPassword,
  usePostSendCode,
  usePostVerifyCode,
} from "../../query/useAuth";
import { toast } from "sonner";
import OTPInput from "react-otp-input";
import { Link } from "react-router-dom";
import ButtonLoading from "../../components/common/ButtonLoading";
import Logo from "/magicreview.svg";
import { Eye, EyeOff } from "lucide-react";
import { AxiosError } from "axios";

export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [sessionId, setSessionId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState<boolean>(false);
  const { mutate, isPending } = usePostSendCode();
  const { mutate: verifyCode, isPending: verifyCodeLoading } =
    usePostVerifyCode();
  const { mutate: resetPassword, isPending: resetPasswordLoading } =
    usePostResetPassword();

  const isMobile = window.innerWidth <= 768;

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      email,
    };

    mutate(data, {
      onSuccess: (res) => {
        toast.success("Verification code has been sent!");
        setStep(2);
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          if (err.response?.data.message) {
            toast.error(err.response?.data.message);
          } else {
            toast.error("Something went wrong!");
          }
        }
      },
    });
  };

  const handleVerifyOtp = async (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    verifyCode(
      {
        code: otp,
        email: email,
      },
      {
        onSuccess: (res) => {
          setSessionId(res.session_id);
          toast.success("Code has been verified!");
          setStep(3);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.data.message) {
              toast.error(err.response?.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          }
        },
      }
    );
  };

  const handleConfirmPassword = async (
    e: React.SyntheticEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      toast.error("Invalid password");
      return;
    } else {
      setIsPasswordValid(true);
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    resetPassword(
      {
        session_id: sessionId,
        password: password,
      },
      {
        onSuccess: (res) => {
          toast.success("Password reset successfully!");
          navigate("/sign-in");
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.data.message) {
              toast.error(err.response?.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          }
        },
      }
    );
  };
  return (
    <div className="w-full min-h-screen flex justify-center items-center py-10">
      <div className="flex flex-col items-center justify-center mt-10 mb-5">
        <img loading="lazy" src={Logo} alt="logo" className="" />

        {step === 1 && (
          <>
            <h1 className="md:text-[32px] font-bold font-opensans text-[#323131] mb-4 mt-4 text-center">
              Reset your password
            </h1>
            <p className="text-[#10182899] mb-10 text-center font-montserrat text-[15px] leading-7">
              Enter your email address below and we&apos;ll send you a
              verification code to verify your email.
            </p>
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="email"
                  className="text-[#585866] font-montserrat text-base mb-2"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  placeholder="e.g. mail@domain.com"
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-400 py-3.5 px-3 rounded-[4px] focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent font-montserrat text-[13.6px]"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={!email.trim()}
                className="bg-primary border border-primary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-primary active:scale-95 w-full"
              >
                {isPending ? <ButtonLoading /> : "Send Verification Code"}
              </button>
            </form>
            <div className="mt-6">
              <Link
                to="/login"
                className="text-[#4F46BA] hover:text-[#4338CA] font-semibold font-montserrat"
              >
                Back to login
              </Link>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <h1 className="md:text-[32px] font-bold font-opensans text-[#323131] mb-4 mt-4 text-center">
              Enter Verification Code
            </h1>
            <p className="text-[#10182899] mb-10 text-center font-montserrat text-[15px] leading-7">
              We sent a code to {email}.
            </p>
            <div>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={8}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
                inputStyle={{
                  width: isMobile ? "35px" : "50px",
                  height: isMobile ? "35px" : "50px",
                  borderRadius: "8px",
                  border: "1px solid black",
                }}
              />
              <div className="flex items-center gap-x-1 font-montserrat text-[14px] leading-7 mt-3">
                <p className="inline">Didn&apos;t receive a code?</p>
                <p
                  className="inline text-primary underline cursor-pointer"
                  onClick={() => setStep(1)}
                >
                  Click to resend
                </p>
              </div>
            </div>
            <button
              disabled={otp.length < 8}
              className="bg-primary border border-primary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-primary active:scale-95 w-full"
              onClick={handleVerifyOtp}
            >
              {verifyCodeLoading ? <ButtonLoading /> : "Verify Code"}
            </button>
          </>
        )}
        {step === 3 && (
          <>
            <h1 className="md:text-[32px] font-bold font-opensans text-[#323131] mb-4 mt-4 text-center">
              Reset your password
            </h1>
            <p className="text-[#10182899] mb-10 text-center font-montserrat text-[15px] leading-7">
              Enter your new password below to reset your password.
            </p>
            <form onSubmit={handleConfirmPassword} className="w-full max-w-md">
              <div className="flex flex-col space-y-2 relative">
                <label htmlFor="email">New Password</label>
                <input
                  type={isShowPassword ? "text" : "password"}
                  id="password"
                  placeholder="************"
                  className="border text-sm rounded-[4px] px-5 py-3
                    focus:appearance-none focus:ring-0 focus:outline-none focus:border-primary placeholder:pt-7"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="absolute top-1/2 -translate-y-0.5 right-5 cursor-pointer">
                  {isShowPassword ? (
                    <EyeOff
                      className="text-[#81818F] w-5 h-5"
                      onClick={() => setIsShowPassword(false)}
                    />
                  ) : (
                    <Eye
                      className="text-[#81818F] w-5 h-5"
                      onClick={() => setIsShowPassword(true)}
                    />
                  )}
                </div>
              </div>
              <br />
              <div className="flex flex-col space-y-2 relative">
                <label htmlFor="email">New Password</label>
                <input
                  type={isShowPassword ? "text" : "password"}
                  id="password"
                  placeholder="************"
                  className="border text-sm rounded-[4px] px-5 py-3
                    focus:appearance-none focus:ring-0 focus:outline-none focus:border-primary placeholder:pt-7"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="absolute top-1/2 -translate-y-0.5 right-5 cursor-pointer">
                  {isShowConfirmPassword ? (
                    <EyeOff
                      className="text-[#81818F] w-5 h-5"
                      onClick={() => setIsShowConfirmPassword(false)}
                    />
                  ) : (
                    <Eye
                      className="text-[#81818F] w-5 h-5"
                      onClick={() => setIsShowConfirmPassword(true)}
                    />
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="bg-primary border border-primary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-primary active:scale-95 w-full"
              >
                {resetPasswordLoading ? <ButtonLoading /> : "Reset Password"}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

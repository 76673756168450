import React from "react";
import { GetUserEntity, GetUserProfile } from "../../types/types";
import { config } from "../../config/config";
import { Mic, Sparkles, SquareCheckBig } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

const Overview = ({
  profile,
  entity,
  first_name,
  last_name,
  mail,
}: {
  profile: GetUserProfile;
  entity: GetUserEntity;
  first_name: string | null;
  last_name: string | null;
  mail: string;
}) => {
  const baseUrl = config.apiUrl;
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-x-5 py-5 px-5 lg:px-10">
          <img
            src={profile?.profile_picture || "/magicreview.svg"}
            alt=""
            className="w-[45px] h-[45px] rounded-full flex-shrink-0"
          />
          <p className="font-medium text-[22px]">
            {first_name + " " + last_name}
          </p>
        </div>
        <button className="bg-primary border border-primary px-5 py-3 rounded-[7px] text-white hover:bg-white hover:text-primary transition-all duration-200 font-montserrat">
          Login as User
        </button>
      </div>
      <hr className="mb-6" />
      <div className="rounded-lg lg:rounded-2xl border bg-white">
        <Table>
          <TableHeader>
            <TableRow className="text-[#888] font-medium font-pro">
              <TableHead></TableHead>
              <TableHead>Description</TableHead>
              <TableHead>No. of Reviews</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow className={`font-pro`}>
              <TableCell>
                <Sparkles className="text-[#4869DF]" />
              </TableCell>
              <TableCell>AI Review Generated</TableCell>
              <TableCell>1000</TableCell>
            </TableRow>
            <TableRow className={`font-pro`}>
              <TableCell>
                <SquareCheckBig className="text-[#4869DF]" />
              </TableCell>
              <TableCell>AI Review Redirected</TableCell>
              <TableCell>500</TableCell>
            </TableRow>
            <TableRow className={`font-pro`}>
              <TableCell>
                <Mic className="text-[#4869DF]" />
              </TableCell>
              <TableCell>Voice Review Posted</TableCell>
              <TableCell>100</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default Overview;

import { createBrowserRouter, RouterProvider, Routes } from "react-router-dom";
import RootLayout from "./components/common/RootLayout";
import Login from "./pages/login/Login";
import Promotion from "./pages/promotion/Promotion";
import Overview from "./pages/overview/Overview";
import Settings from "./pages/settings/Settings";
import ProtectedRoute from "./context/ProtectedRoute";
import ForgotPassword from "./pages/login/ForgotPassword";
import Plans from "./pages/plans/Plans";
import Users from "./pages/users/Users";
import Prompt from "./pages/prompt/Prompt";

const router = createBrowserRouter([
  {
    path: "/",
    Component: () => (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/promotion",
        element: <Promotion />,
      },
      {
        path: "/plans",
        element: <Plans />,
      },
      {
        path: "/prompt",
        element: <Prompt />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import { useGetRecentSubscription } from "../../query/useUser";

const RecentSubscriptions = () => {
  const { data } = useGetRecentSubscription();
  return (
    <div className="w-full xl:w-[40%] p-5 rounded-[12px] bg-white min-h-max">
      <div className="flex justify-between items-center">
        <p className="text-xl font-medium">Recent Subscriptions</p>
        <Link to={"#"} className="text-[11px] text-[#888] underline">
          See all
        </Link>
      </div>
      <div className="mt-5 space-y-3 max-h-full overflow-y-auto scrollbar-thin">
        {data?.data?.map((item, i) => (
          <div
            className="flex items-center justify-between"
            key={item?.user_email}
          >
            <div className="flex items-center gap-x-3">
              <div>
                <img
                  src="/magicreview.svg"
                  alt=""
                  className="w-[30px] h-[30px] rounded-full flex-shrink-0"
                />
              </div>
              <div className="">
                <p className="text-sm text-[#252323]">{item?.user_fullname}</p>
                <p className="text-[10px] text-[#888]">{item?.user_email}</p>
              </div>
            </div>
            <div className="bg-lightpurple text-[#615E83] flex justify-center items-center gap-x-1 px-1.5 py-1 rounded-full">
              <p className="font-medium text-[11px]">{item?.plan_duration}</p>
              <p className="font-medium text-[10px]">({item?.plan_currency})</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentSubscriptions;

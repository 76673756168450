import React from "react";

const ButtonLoading = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin group-hover:border-primary"></div>
    </div>
  );
};

export default ButtonLoading;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import ButtonLoading from "../common/ButtonLoading";
import { GetPromptListReturn } from "../../types/types";
import { usePutEditPrompt } from "../../query/usePrompt";
import { toast } from "sonner";
import { AxiosError } from "axios";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  data: GetPromptListReturn;
};

const EditPromptDialog = ({
  isOpenDialog,
  setIsOpenDialog,
  refetch,
  data,
}: Props) => {
  const contextRef = useRef(null);
  const taskRef = useRef(null);
  const [title, setTitle] = useState<string>("");
  const [context, setContext] = useState<string>("");
  const [task, setTask] = useState<string>("");

  const { mutate, isPending } = usePutEditPrompt();

  const handleContextFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setContext(e.target.result as string);
      };
      reader.readAsText(file);
    }
  };

  const handleTaskFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setTask(e.target.result as string);
      };
      reader.readAsText(file);
    }
  };

  const handleUpdatePrompt = () => {
    mutate(
      {
        id: data?.id,
        data: {
          title: title || "",
          context: context || "",
          task: task || "",
        },
      },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("Prompt has been updated");
          }
          setIsOpenDialog(false);
          refetch();
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Something went wrong");
            }
          }
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setContext(data?.context);
      setTask(data?.task);
    }
  }, [data]);

  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent
        className="bg-white !rounded-2xl !max-w-fit p-5 lg:p-10 font-poppins"
        data-testid="add-prompt-dialog"
      >
        <p className="font-semibold text-[22px]">Edit a Prompt</p>
        <input
          type="text"
          className="w-[300px] lg:w-[650px] h-[55px] rounded-lg bg-[#7d7a9a]/5 px-5 text-[22px] border border-[#7d7a9a]/35"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
        />
        <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-5">
          <div className="w-full flex flex-col items-end gap-y-3">
            <input
              type="file"
              ref={contextRef}
              hidden
              onChange={handleContextFileChange}
              accept=".txt"
            />
            <button
              className="bg-[#dadfff] rounded-lg text-[#7582cf] px-2.5 py-1.5"
              onClick={() => contextRef.current.click()}
            >
              Import
            </button>
            <textarea
              className="w-full rounded-lg bg-[#7d7a9a]/5 p-5 resize-none border border-[#7d7a9a]/35 scrollbar-thin"
              placeholder="Context"
              value={context}
              onChange={(e) => setContext(e.target.value)}
              rows={5}
            ></textarea>
          </div>
          <div className="w-full flex flex-col items-end gap-y-3">
            <input
              type="file"
              ref={taskRef}
              hidden
              onChange={handleTaskFileChange}
              accept=".txt"
            />
            <button
              className="bg-[#dadfff] rounded-lg text-[#7582cf] px-2.5 py-1.5"
              onClick={() => taskRef.current.click()}
            >
              Import
            </button>
            <textarea
              className="w-full rounded-lg bg-[#7d7a9a]/5 p-5 resize-none border border-[#7d7a9a]/35 scrollbar-thin"
              placeholder="Task"
              value={task}
              onChange={(e) => setTask(e.target.value)}
              rows={5}
            ></textarea>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-5 lg:gap-x-10">
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
            onClick={handleUpdatePrompt}
          >
            {isPending ? <ButtonLoading /> : "Update Prompt"}
          </button>
          <button
            className="px-7 py-2.5 rounded-[12px] bg-lightpurple text-[#7D7A9A]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200"
            onClick={() => setIsOpenDialog(false)}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditPromptDialog;
